import { Card, Grid, Image } from '@enterprise-ui/canvas-ui-react'
import HomeImage from '../../globalImages/Office_computer.png'
export const UnifiedAppDetails = ({ productReleaseNotes }) => {
  return (
    <Card className="hc-pa-normal home-header-card">
      <Grid.Container>
        <Grid.Item xs={8}>
          <h2 className="hc-fs-md font-color-default hc-lh-expanded">
            ItemHub
          </h2>
          <h6 className="hc-fs-sm font-color-default hc-lh-expanded hc-mt-dense font-weight-400">
            Centralized platform to manage end-to-end item information{' '}
          </h6>
          {/* <Button
            as="a"
            type="ghost"
            className="hc-fs-sm hc-clr-interactive hc-pl-none"
          >
            Read More
          </Button> */}
        </Grid.Item>
        <Grid.Item xs={4}>
          <Image
            data-testid="unified-item-interface-image"
            src={HomeImage}
            height="22%"
            width="30%"
            alt="Unified Item Interface Details"
          />
        </Grid.Item>
      </Grid.Container>
    </Card>
  )
}
