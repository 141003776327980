export const isTarget = (url) => {
  url = url ? url : window.location.href
  // return /(localhost|^([^/]+:)?\/{2,3}[^/]+?(\.target\.com)(:|\/|$))/i.test(url)
  return /(^([^/]+:)?\/{2,3}[^/]+?(\.target\.com)(:|\/|$))/i.test(url)
}
export const isProd = (url) => {
  url = url ? url : window.location.href
  return /https?:\/\/(itemhub(\.(prod))?)\.(target|partnersonline)\.com/.test(
    url,
  )
}
