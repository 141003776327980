import { BrowserRouter as Router } from 'react-router-dom'
import '@enterprise-ui/canvas-ui-css'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import { AuthProvider } from '@praxis/component-auth'
import { AnalyticsProvider } from '@praxis/component-analytics'

import './globalStyles/customStyles.scss'
import { Main } from './views/Main'
import apiConfig from './globalConfig/apiConfig'

const App = () => {
  return (
    <>
      <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
      <Router>
        <AuthProvider {...apiConfig.auth}>
          <AnalyticsProvider
            eventManagerConfiguration={apiConfig.analytics}
            trackLocations
          >
            <Main />
          </AnalyticsProvider>
        </AuthProvider>
      </Router>
    </>
  )
}

export default App
