import { Card, Placeholder, Grid } from '@enterprise-ui/canvas-ui-react'

export const ContentPlaceholder = (props) => {
  return (
    <Placeholder>
      <Grid.Container style={{ width: 450 }}>
        {Array.from(Array(props.length).keys()).map((index) => {
          return (
            <Grid.Item md={12} xs={12} key={index}>
              <Card
                className="hc-pa-md bg-default-contrast-weak font-color-default"
                padding="dense"
                data-testid="placeholder-content"
              >
                <Grid.Container>
                  <Grid.Item md={12}>
                    <Placeholder.Text emphasized />
                  </Grid.Item>
                </Grid.Container>
                <Placeholder.Rect height="130px" />
                <Placeholder.Text emphasized />
              </Card>
            </Grid.Item>
          )
        })}
      </Grid.Container>
    </Placeholder>
  )
}
