import { Routes, Route, useLocation } from 'react-router-dom'
import { ErrorBoundary } from '@praxis/component-logging'
import { ErrorContent } from '../globalComponents/ErrorContent'
import { HomeComponent } from '../views/Home/HomeComponent'

export const MainRouter = ({ appMenus }) => {
  const location = useLocation()
  return (
    <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
      <Routes>
        <Route path="/" element={<HomeComponent appMenus={appMenus} />}></Route>
      </Routes>
    </ErrorBoundary>
  )
}
