import { Card, Grid, Layout } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { BrandIcon } from '@enterprise-ui/icons'
import { useAuth } from '@praxis/component-auth'

export const LogIn = () => {
  const auth = useAuth()
  const { login, isAuthenticated } = auth
  if (!isAuthenticated()) {
    login()
  }

  return (
    <Layout data-testid="loginLayout" fullWidth>
      <Layout.Body includeRail>
        <Grid.Container
          className="praxcss-height100vh"
          align="center"
          justify="center"
        >
          <Grid.Item xs={10} md={6} lg={4}>
            <Card
              className="hc-pa-normal"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {/* <Heading className="hc-ta-center hc-pb-normal">
                Welcome to Your App!
              </Heading> */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <EnterpriseIcon
                  icon={BrandIcon}
                  size="xl"
                  style={{ height: 36, width: 36 }}
                />
                <h3 className="hc-fs-xl hc-lh-expanded hc-ml-dense">ItemHub</h3>
              </div>
            </Card>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </Layout>
  )
}

export default LogIn
