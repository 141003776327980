import { useState } from 'react'
import {
  Card,
  Grid,
  Button,
  Modal,
  Anchor,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { FixturesIcon } from '@enterprise-ui/icons'
import { ContentPlaceholder } from './ContentPlaceholder'
export const ProductReleaseContents = ({ productReleaseNotes }) => {
  const [isReadMoreModalOpen, setIsReadMoreModalOpen] = useState(false)
  const [releaseContent, setReleaseContent] = useState(null)
  const readReleaseContent = (releaseContent) => {
    setReleaseContent(releaseContent)
    setIsReadMoreModalOpen(true)
  }
  return (
    <>
      <Card className="hc-pa-md hc-bg-contrast-weak home-relase-cards">
        <p
          className="hc-fs-md font-color-default hc-lh-expanded hc-mt-dense font-weight-700"
          style={{ display: 'flex' }}
        >
          <EnterpriseIcon icon={FixturesIcon} className="hc-mr-dense" />{' '}
          <h3 className="hc-fs-md font-color-default hc-lh-expanded font-weight-700">
            What’s New?
          </h3>
        </p>
        {productReleaseNotes.length === 0 ? (
          <ContentPlaceholder
            data-testid="placeholder-content-release"
            length={2}
          />
        ) : (
          <Grid.Container>
            {productReleaseNotes?.map((appRelease) => (
              <Grid.Item md={12} xs={12} key={appRelease.id}>
                <Card
                  className="hc-pa-md bg-default-contrast-weak font-color-default"
                  padding="dense"
                >
                  <Grid.Container>
                    <Grid.Item md={6}>
                      <h4 className="hc-fs-md font-color-default font-weight-500">
                        {appRelease?.attributes?.Release_feature_name}
                      </h4>
                      <span className="hc-fs-xs product-relase-launched-date font-weight-500">
                        Launched on {appRelease?.attributes?.Launch_date}
                      </span>
                    </Grid.Item>
                    <Grid.Item md={6} style={{ textAlign: 'end' }}>
                      <span className="hc-fs-min home-release-notes-app-names font-color-secondary font-weight-500">
                        {appRelease?.attributes?.Application_name}
                      </span>
                    </Grid.Item>
                  </Grid.Container>
                  <h5
                    className="hc-fs-sm app-description font-color-default hc-mt-normal hc-mb-normal font-weight-400"
                    dangerouslySetInnerHTML={{
                      __html:
                        appRelease?.attributes?.Release_short_description?.substring(
                          0,
                          200,
                        ),
                    }}
                  />
                  <Button
                    as="button"
                    type="ghost"
                    onClick={(e) => readReleaseContent(appRelease?.attributes)}
                    className="hc-fs-sm hc-mt-normal hc-clr-interactive hc-pl-none font-weight-500"
                    data-testid="read-more-button"
                    aria-label="Read More Content"
                  >
                    Read More
                  </Button>
                </Card>
              </Grid.Item>
            ))}
          </Grid.Container>
        )}
      </Card>
      {/* View Release Notes when click on Read More button*/}
      <Modal
        headingText={releaseContent?.Release_feature_name}
        isVisible={isReadMoreModalOpen}
        onRefuse={() => setIsReadMoreModalOpen(false)}
        scrollBehavior="contained"
        footer
        size="normal"
        className="release-content-modal"
      >
        <div style={{ padding: '0 24px' }}>
          <span className="hc-fs-md product-relase-launched-date font-weight-400 hc-clr-interactive">
            Launched on {releaseContent?.Launch_date}
          </span>
          <p
            className="hc-fs-md hc-mt-normal app-description font-color-default font-weight-400"
            dangerouslySetInnerHTML={{
              __html: releaseContent?.Release_short_description,
            }}
          />
          {releaseContent?.Read_more_link && (
            <Anchor
              href={releaseContent?.Read_more_link}
              target="_blank"
              data-testid="read-more-details"
              aria-label="More Details"
            >
              More Details
            </Anchor>
          )}
        </div>
      </Modal>
    </>
  )
}
