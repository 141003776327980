import merge from 'lodash/merge'
import { isTarget } from '../globalUtills/common'
import externalConfigs from './apiConfigExternal'
// import { config as analyticsConfig } from '@praxis/component-analytics'
const commonConfig = {
  applicationName: 'ItemHub',
  auth: {
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
  },
}
const envConfigs = {
  dev: {
    auth: {
      clientId: 'itemapp_npe_im',
      loginRedirect: `${window.location.origin}`,
    },
    strapi: {
      // base: 'https://itemstrapi.dev.target.com/api/',
      // productsRelease:
      //   'unified-application-release-notes?sort[0]=Launch_date:desc',
      // productMenuDetails: 'unified-application-products-menus',
      base: `${window.location.origin}`,
      productsRelease: '/api/unified-application-release-notes',
      productMenuDetails: '/api/unified-application-products-menus',
    },
    analytics: {
      appName: 'itemhub',
      url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
      apiKey: '4abdb7e4f9c566211770cfe6f290abd5ef84aa8f',
    },
  },
  prod: {
    auth: {
      clientId: 'itemapp_prod_im',
      loginRedirect: `${window.location.origin}`,
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
    },
    strapi: {
      base: `${window.location.origin}`,
      productsRelease: '/api/unified-application-release-notes',
      productMenuDetails: '/api/unified-application-products-menus',
    },
    analytics: {
      appName: 'itemhub',
      url: 'https://api.target.com/consumers/v1/ingest/internal/internal_app',
      apiKey: 'e7ef0c21bdda62557dca72a0357a20bcf69109a2',
    },
  },
}
const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]

let apiConfig = merge(commonConfig, config)

if (!isTarget()) {
  apiConfig = merge(apiConfig, externalConfigs[appEnv])
}

export default apiConfig
