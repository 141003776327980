/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { Grid, Layout } from '@enterprise-ui/canvas-ui-react'

import { UnifiedAppDetails } from './UnifiedAppDetails'
import { ItemProducts } from './ItemProducts'
import { ProductReleaseContents } from './ProductReleaseContent'
import { fetchProductReleaseNotes } from '../../globalServices/itemhubServices'

export const HomeComponent = ({ appMenus }) => {
  const auth = useAuth()
  const [appReleaseContents, setAppReleaseContent] = useState([])
  useEffect(() => {
    getProductReleaseNotes()
  }, [])

  const getProductReleaseNotes = async () => {
    try {
      const releaseContents = await fetchProductReleaseNotes(auth)
      setAppReleaseContent(releaseContents)
    } catch (error) {
      console.error('Error fetching product release notes:', error)
      setAppReleaseContent([])
    }
  }

  return (
    <>
      {/* <SectionHeader pageHeading="" /> */}
      <Layout.Body
        data-testid="homePage"
        includeRail
        className="layout-body-section"
      >
        <Grid.Container>
          <Grid.Item xs={12}>
            <UnifiedAppDetails />
          </Grid.Item>
          <Grid.Item xs={8} style={{ display: 'grid' }}>
            <ItemProducts appMenus={appMenus} />
          </Grid.Item>
          <Grid.Item xs={4} className="display-flex">
            <ProductReleaseContents productReleaseNotes={appReleaseContents} />
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </>
  )
}
