import { useState, useEffect } from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { Header, SideNavigation } from 'item-component'
import { LogIn } from './LogIn'
import { MainRouter } from '../globalComponents/MainRouter'
import { fetchProductDetailsAndMenus } from '../globalServices/itemhubServices'

export const Main = () => {
  const auth = useAuth()
  const [productMenusDetails, setProductMenusDetails] = useState([])

  const { isAuthenticated } = auth
  useEffect(() => {
    getProductDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getProductDetails = async () => {
    try {
      const menuDetails = await fetchProductDetailsAndMenus(auth)
      if (menuDetails?.message) {
        setProductMenusDetails([])
      } else {
        setProductMenusDetails(menuDetails)
      }
    } catch (error) {
      console.error('Error fetching product details and menus:', error)
      setProductMenusDetails([])
    }
  }

  if (isAuthenticated()) {
    return (
      <Layout
        theme="default"
        darkMode="false"
        data-testid="mainAuthenticatedLayout"
      >
        <Header useAuth={useAuth} isSupportVisible={true} />
        <SideNavigation
          className="side-navigation-panel"
          data-testid="item-app-sidenav-panel"
          startExpanded={false}
          appMenus={productMenusDetails}
          defaultmenurequired={true}
        />
        <MainRouter appMenus={productMenusDetails} />
      </Layout>
    )
  } else {
    return <LogIn />
  }
}
